// EmploymentList.js
import React from 'react';
import './EmploymentList.css';
import nvidia from '../assets/employment/Nvidia.jpg';
import advantest from '../assets/employment/Advantest.jpg';
import poppin from '../assets/employment/Poppin.jpg';
import publicComps from '../assets/employment/PublicComps.jpg';
import autoLatex from '../assets/employment/Autolatex.jpg';

import mit from '../assets/research/MIT.jpg';
import ucla from '../assets/research/UCLA.jpg';
import asdrp from '../assets/research/ASDRP.jpg';
import umassAmherst from '../assets/research/UMassAmherst.jpg';

// import bcg from '../assets/consulting/BCG.jpg';
import gumgum from '../assets/consulting/GumGum.jpg';
import dimensional from '../assets/consulting/Dimensional.jpg';

const employmentData = [
  {
    company: 'Nvidia',
    position: 'Software Intern',
    startDate: 'Jun 2024',
    endDate: 'Sep 2024',
    logo: nvidia,
  },
  {
    company: 'Advantest',
    position: 'Software Research & Development Intern',
    startDate: 'Jun 2023',
    endDate: 'Sep 2023',
    logo: advantest,
  },
  {
    company: 'Poppin',
    position: 'Software Engineer',
    startDate: 'Apr 2023',
    endDate: 'Jun 2023',
    logo: poppin,
  },
  {
    company: 'Public Comps',
    position: 'Software Engineer',
    startDate: 'Mar 2023',
    endDate: 'Jun 2023',
    logo: publicComps,
  },
  {
    company: 'AutoLaTeX',
    position: 'Software Engineer',
    startDate: 'Jan 2021',
    endDate: 'Oct 2022',
    logo: autoLatex,
  },
];

const researchData = [
  {
    company: 'MIT',
    position: 'Deep Learning Researcher',
    startDate: 'Jun 2024',
    endDate: 'Present',
    logo: mit,
  },
  {
    company: 'UCLA',
    position: 'Deep Learning Researcher',
    startDate: 'Mar 2023',
    endDate: 'Present',
    logo: ucla,
  },
  {
    company: 'ASDRP',
    position: 'Deep Learning Researcher',
    startDate: 'Jun 2021',
    endDate: 'May 2022',
    logo: asdrp,
  },
  {
    company: 'UMass Amherst',
    position: 'Biology Researcher',
    startDate: 'Jun 2019',
    endDate: 'Aug 2019',
    logo: umassAmherst,
  },
];

const consultingData = [
  // {
  //   company: 'Boston Consulting Group',
  //   position: 'Consultant -- Contract',
  //   startDate: 'Nov 2024',
  //   endDate: 'Present',
  //   logo: bcg,
  // },
  {
    company: 'GumGum',
    position: 'AI Consultant -- Contract',
    startDate: 'Apr 2024',
    endDate: 'Jun 2024',
    logo: gumgum,
  },
  {
    company: 'Dimensional',
    position: 'Strategy Consultant -- Contract',
    startDate: 'Apr 2023',
    endDate: 'Jun 2023',
    logo: dimensional,
  },
];

function EmploymentSection({ title, data }) {
  return (
    <section className="employment-list">
      <h2>{title}</h2>
      <ul className="employment-list-items">
        {data.map((job, index) => (
          <li key={index} className="employment-item">
            <div className="logo-container">
              <img src={job.logo} alt={`${job.company} logo`} className="company-logo" />
            </div>
            <div className="employment-details">
              <div className="job-title">
                <span className="position">{job.position}</span>
              </div>
              <div className="employment-dates">
                {job.startDate} - {job.endDate}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}

function EmploymentList() {
  return (
    <div>
      <EmploymentSection title="Employment History" data={employmentData} />
      <EmploymentSection title="Research" data={researchData} />
      <EmploymentSection title="Consulting" data={consultingData} />
    </div>
  );
}

export default EmploymentList;
