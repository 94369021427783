import React from 'react';
import './TickerBar.css';
import cbsLogo from '../assets/news/CBSNews.jpg';
import mercuryLogo from '../assets/news/MercuryNews.jpg';
// import nytLogo from '../assets/news/NewYorkTimes.jpg';

const TickerBar = () => {
  const pressItems = [
    { logo: cbsLogo, link: 'https://www.cbsnews.com/losangeles/news/coronavirus-ucla-hackathon-remote-learning/' },
    { logo: mercuryLogo, link: 'https://www.mercurynews.com/2021/09/17/merit-scholarships/' },
    // { logo: nytLogo, link: 'https://www.eastbaytimes.com/2021/09/17/merit-scholarships/' },
  ];

  return (
    <div className="ticker-container">
      <h2 className="press-heading">Press</h2>
      <div className="ticker-row">
        {pressItems.map((press, index) => (
          <div key={index} className="press-item">
            <a href={press.link} target="_blank" rel="noopener noreferrer">
              <img src={press.logo} alt="press logo" className="press-logo" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TickerBar;
