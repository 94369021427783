import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Awards from "./components/Awards";
import Projects from "./components/Projects";
import Research from "./components/Research";
import Entrepreneurship from "./components/Entrepreneurship";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<About />} />
          <Route path="/Awards" element={<Awards />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Research" element={<Research />} />
          <Route path="/Entrepreneurship" element={<Entrepreneurship />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;