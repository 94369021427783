import React from 'react';
import { NavLink } from 'react-router-dom';
import "./Navbar.css"

function Navbar() {
    return (
        <div className="navbar">
            <nav>
                <div className="name">
                    {/* <a href="/">Howard Huang</a> */}
                </div>
                <div className="menuItems">
                    <NavLink exact to="/" activeClassName="active">About</NavLink>
                    {/* <NavLink exact to="/Awards" activeClassName="active">Awards</NavLink> */}
                    <NavLink exact to="/Projects" activeClassName="active">Projects</NavLink>
                    <NavLink exact to="/Research" activeClassName="active">Research</NavLink>
                    {/* <NavLink exact to="/Entrepreneurship" activeClassName="active">Entrepreneurship</NavLink> */}
                </div>
            </nav>
        </div>
    );
};

export default Navbar;