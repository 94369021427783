import React from 'react';
import './Research.css';
import fireblightPoster from '../assets/researchPosters/FireblightPoster.jpg';
import asdrpPoster from '../assets/researchPosters/ASDRPPoster.jpg';
import quantitativeDef from '../assets/researchPosters/QuantitativeDef.jpg';
import comediffPoster from '../assets/researchPosters/CoMEDiff.jpg';

function Research() {
  return (
    <div className="research-container">
      <h2>Research</h2>
      <div className="poster-gallery">
        <img src={fireblightPoster} alt="Fireblight Poster" className="research-poster" />
        <img src={asdrpPoster} alt="ASDRP Poster" className="research-poster" />
        <img src={quantitativeDef} alt="Quantitative Defense Poster" className="research-poster" />
        <img src={comediffPoster} alt="CoMEDiff Poster" className="research-poster blurred-poster" />
      </div>
    </div>
  );
}

export default Research;
