import React from 'react';
import { FaGithub, FaLinkedin, FaDev, FaEnvelope } from 'react-icons/fa';
import me from '../assets/me.png';
import "./About.css";
import NumberCard from './NumberCard';
// import SkillsGrid from './SkillsGrid';
import EmploymentList from './EmploymentList';
import TickerBar from './TickerBar';

function About() {
  return (
    <div className="master-container">
      <div className="header">
        <h3>About</h3>
        <h1>Howard Huang</h1>
      </div>
      <div className="divided-container">
        <div className="picture">
          <img src={me} alt="" />
        </div>
        <div className="content">
          <p>
            I'm an undergrad studying Computer Science at UCLA. Broadly, my academic interests center around GenAI in longevity, drug discovery, video synthesis, 3D reconstruction with NeRFs and Guassian Splatting, and AI in legged robotics. I have 5 years of software experience, 8 hackathon wins, and have created apps with 30M+ users. Feel free to reach out!
          </p>
          <div className="links-container">
            <div className="links">
              <ul>
                <li><FaGithub className="icon" /> <strong>Github:</strong> <span><a href="https://github.com/HowardHuang1" target="_blank" rel="noopener noreferrer">HowardHuang1</a></span></li>
                <li><FaLinkedin className="icon" /> <strong>LinkedIn:</strong> <span><a href="https://www.linkedin.com/in/howardhuangz/" target="_blank" rel="noopener noreferrer">LinkedIn</a></span></li>
                <li><FaEnvelope className="icon" /> <strong>Email:</strong> <span>howardhuangb@gmail.com</span></li>
              </ul>
            </div>
            <div className="links">
              <ul>
                <li><FaDev className="icon" /> <strong>Devpost:</strong> <span><a href="https://devpost.com/howardhuang2004" target="_blank" rel="noopener noreferrer">howardhuang2004</a></span></li>
                <li><FaDev className="icon" /> <strong>Devfolio:</strong> <span><a href="https://devfolio.co/@Howard" target="_blank" rel="noopener noreferrer">https://devfolio.co/@Howard</a></span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="statsbar">
        <NumberCard title="App Downloads" number={34000000} />
        <NumberCard title="Projects" number={24} />
        <NumberCard title="Dollars Raised" number={13000} />
        <NumberCard title="Awards" number={18} />
      </div>
      <div className="employment-section">
        <EmploymentList />
      </div>
      <div className="skills-section">
        <TickerBar />
      </div>
    </div>
  );
}

export default About;
