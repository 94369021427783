import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import "./NumberCard.css"

const NumberCard = ({ title, number }) => {
  useEffect(() => {
    // You can perform any additional setup or animations here
  }, []);

  return (
    <div className="card">
      <h3>      
        <CountUp end={number} duration={2} />
      </h3>
      <p>{title}</p>
    </div>
  );
};

export default NumberCard;
